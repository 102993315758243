import React, { Component } from 'react';
import { ENV_TYPE } from '../../constants';
import { connect } from 'react-redux';
import { addInterviewTimesAPI, fetchUserDetails, jobs, job, deleteSlotAPI, getInterviewSlotsFilteredByInterviewer } from '../helperFunctions/dataServices.js'
import moment from "moment"
import 'moment/locale/ja'
import DashboardTranslations from '../../DashboardTranslations.js';
import { getErrorMessage } from '../../messages/errors';
import ErrorModal from '../components/ErrorModal'
import InnerHeader from '../components/InnerHeader.js'
import CustomEvent from './CustomEvent';
import CustomToolbar from './CustomToolbar';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ReactGA from 'react-ga';
import SuccessModal from '../components/SuccessModal'
import BigCalendar from "react-big-calendar"
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css"
import 'react-base-table/styles.css'
import 'react-day-picker/lib/style.css';
import './styles/addInterviewTimes.scss';
import InfoModalAddSlotCalendar from "../components/InfoModalAddSlotCalendar";
import questionMark from "../../images/svg/question-mark2.png";
const reactStringReplace = require('react-string-replace');
moment.locale('ja')

function isGapAtLeast24Hours(date1, date2) {
  const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours >= 24;
}
class AddInterviewTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      showSuccess: false,
      showError: false,
      jobId: 0,
      slotsData: [],
      specialLoading: false,
      singleJob: [],
      loading: false,
      newlyAddedSlotsArray:[],
      currentJobName: "",
      currentInterviewerId: 0,
      interviewerLastName: "",
      loggedInUserLastName: "",
      showAddSlotCalendarModal: false
    }
  }

  componentDidMount() {
    var id = this.props.match.params.id
    this.fetchDetails()
    this.setState({jobId: id})
    this.getJobs()
    this.getJob(id)
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }

    // //////////////////////

    const keyName = 'hasSeenAddSlotCalendarModal' + this.props.user.email;
    const hasSeenAddSlotCalendarModal = localStorage.getItem(keyName);

    if (hasSeenAddSlotCalendarModal !== 'true') {
      this.setState({showAddSlotCalendarModal: true});
      localStorage.setItem(keyName, 'true');
    }

    // ///////////////////

  }

  convertExpiryTimestamp(timestamp) {
    let date = new Date(timestamp*1000);
    date.setDate(date.getDate() + 10);

    const cDate = date.getDate();
    const cMonth = date.getMonth();
    const cYear = date.getFullYear();

    if (timestamp !== null) {
      return [cYear, "年", cMonth+1, "月", cDate, "日"].join('');
    }
    else {
      return "";
    }
  }

  fetchDetails() {
    this.setState({loading: true})
    fetchUserDetails().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var lastName = response.data.last_name
        this.setState({loggedInUserLastName: lastName, loading: false})
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/add-interview-times');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.joyride !== this.props.joyride) {
      this.checkForJoyride()
    }
  }

  handleClose() {
    this.setState({showError: false, showSuccess: false})
  }

  closeInfoModalAddSlotCalendar() {
    this.setState({showAddSlotCalendarModal: !this.state.showAddSlotCalendarModal});
  }

  getJobs() {
    this.setState({loading: true})
    jobs().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        response.data.sort(this.compareInterviewLocation);
        var id = this.props.match.params.id
        var jobId = this.state.jobId
        if (parseInt(id) === 0) {
          this.props.history.push('/dashboard/jobs')
          jobId = response.data[0].id
          this.getJob(jobId)
        }
        this.setState({loading: false, jobs: response.data, jobId: jobId})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getJob(id, special) {
    if (special) {
      this.setState({specialLoading: true})
    }
    else {
      this.setState({loading: true, specialLoading: true})
    }
    job(id).then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({currentJobName: response.data.web_id, currentInterviewerId: response.data.interview_in_charge.id, interviewerLastName: response.data.interview_in_charge.last_name})
        if (special) {
          this.getInterviewSlotsFilteredByInterviewer(response.data.interview_in_charge.id, true)
        }
        else {
          this.getInterviewSlotsFilteredByInterviewer(response.data.interview_in_charge.id)
        }
    		this.setState({singleJob: response.data, loading: false})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  getInterviewSlotsFilteredByInterviewer(id, special) {
    if (special) {
      this.setState({specialLoading: true})
    }
    else {
      this.setState({loading: true, specialLoading: true})
    }
    getInterviewSlotsFilteredByInterviewer(id).then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        var data = response.data
        data = data.map(slot => {
          // console.log(slot.job_id, this.state.jobId);
          if (parseInt(slot.job_id) === parseInt(this.state.jobId)) {
            slot.isCurrentJobSlot = true
          }
          else {
            slot.isCurrentJobSlot = false
          }
          return slot;
        })
        this.setState({loading: false, specialLoading: false, slotsData: data})
    	}
    	else {
    		this.setState({loading: false, specialLoading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false, specialLoading: false})
    })
  }

  backArrowOnClick() {
    if(this.props.location.search.includes('new-job')) {
      this.props.history.push('/dashboard')
    } else {
      this.props.history.goBack();
    }
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  onSelectSlot(details) {
    var canAddSlots = new Date();
    // canAddSlots.setHours(canAddSlots.getHours() + 96);
    if (isGapAtLeast24Hours(details.start, canAddSlots)) {
      var newlyAddedSlotsArray = []
      var startTime = moment(details.start).format('HH:mm');
      var endTime = moment(details.end).format('HH:mm');
      var startDay = this.formatDate(details.start)
      var endDay = this.formatDate(details.end)
      if (endTime === "00:00") {
        endTime = "00:30"
      }
      else if (endTime === "23:59") {
        var nextDay = moment(details.end).add(1, 'days')
        var endDay = this.formatDate(nextDay)
        endTime = "00:00"
      }
      var newStart = startDay + " " + startTime
      var newEnd = endDay + " " + endTime
      newlyAddedSlotsArray.push({start_time: newStart, end_time: newEnd})
      this.setState({newlyAddedSlotsArray: newlyAddedSlotsArray}, () => this.submit())
    } else {
      let errorTitle = 'この枠は登録できません'
      let error = '新規の面接枠は24時間以降\nの枠から登録できます。'
      this.setState({specialLoading: false, showError: true, error, errorTitle})
    }
  }

  onSelectSlotMobile(details) {
    var canAddSlots = new Date();
    // canAddSlots.setHours(canAddSlots.getHours() + 96);
    if (details.start > canAddSlots) {
      var newlyAddedSlotsArray = []
      var startTime = moment(details.start).format('HH:mm');
      var endTime = moment(details.end).format('HH:mm');
      var day = this.formatDate(details.start)
      var newStart = day + " " + startTime
      var newEnd = day + " " + endTime
      newlyAddedSlotsArray.push({start_time: newStart, end_time: newEnd})
      this.setState({newlyAddedSlotsArray: newlyAddedSlotsArray}, () => this.submit())
    }
  }

  submit() {
    var payload = {}
    this.setState({specialLoading: true})
    if (this.state.newlyAddedSlotsArray.length > 0) {
      payload.job = parseInt(this.props.match.params.id)
      payload.slots = this.state.newlyAddedSlotsArray
      addInterviewTimesAPI(payload).then((response) => {
      	if (response && response.status >= 200 && response.status < 300) {
          var data = response.data
          data = data.map(slot => {
            // console.log(slot.job_id, this.state.jobId);
            if (parseInt(slot.job_id) === parseInt(this.state.jobId)) {
              slot.isCurrentJobSlot = true
            }
            else {
              slot.isCurrentJobSlot = false
            }
            return slot;
          })
          this.setState({loading: false, specialLoading: false, slotsData: data})
          setTimeout(() => {
            if(this.props.updateUrgentUpdate) {
              this.props.updateUrgentUpdate()
            }
          }, 500)
          // this.setState({specialLoading: false, redirect: true, successMessage: "面接枠を登録しました。"}, () => this.getJob(this.props.match.params.id, true))
      	}
      	else {
          const errorMessage = response.response.data.detail
          let errorTitle = '';
          let error = '';
          if(errorMessage === 'Same time slot for same job not allowed for a single interviewer') {
            errorTitle = 'すでに登録ずみです'
            error = 'この枠にはあなたの面接枠が すでに登録されています。'
          } else if(errorMessage === 'Maximum allowed concurrent slots: 5, reached') {
            errorTitle = '登録は1枠5件まで'
            error = '一人の面接担当者が複数の求人記事を\n担当している場合、同じ枠に5件まで登録できます。'
          } else if(errorMessage.includes('must be in future.')) {
            errorTitle = 'この枠は登録できません'
            error = '新規の面接枠は24時間以降\nの枠から登録できます。'
          } else {
            error = ''
          }
      		this.setState({specialLoading: false, showError: true, error, errorTitle})
      	}
      })
      .catch((e) => {
        this.setState({specialLoading: false, showError: true, error: getErrorMessage('add_interview_times_select_date_and_time_error_message')})
      })
    }
    else {
      this.setState({showError: true, error: getErrorMessage('add_interview_times_select_date_and_time_error_message')})
    }
  }

  deleteSlot(id) {
    this.setState({specialLoading: true})
    deleteSlotAPI(id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({specialLoading: false, loading: false}, () => this.getInterviewSlotsFilteredByInterviewer(0, true))
        // this.setState({deleteModal: false, specialText: "", loading: false, redirect: false, showSuccess: true, successMessage: DashboardTranslations.interview_times_delete_slot_success_message}, () => this.getInterviewSlotsFilteredByInterviewer(this.state.singleJob.interview_in_charge.id))
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  redirectToApplicantProfile(id) {
    var url = "dashboard/applicant-details/"+id
    this.props.history.push("dashboard/jobs")
  }

  render() {
    var { error, errorTitle, showError, showSuccess, successMessage, loading, specialLoading, slotsData, currentJobName,
      currentInterviewerId, interviewerLastName, singleJob, showAddSlotCalendarModal } = this.state;

    var disableDays = new Date()
    disableDays.setDate(disableDays.getDate() + 2)
    const localizer = BigCalendar.momentLocalizer(moment) // or globalizeLocalizer

    var myEvents = []

    if (slotsData.length > 0) {
      myEvents = slotsData.map((slot, id) => {
        var showDelete = true
        var applicantId
        if (slot.job_application && Object.keys(slot.job_application).length > 0) {
          showDelete = false
          applicantId = slot.job_application.id
        }
        var deleteColor = '#FFF'
        var bookingColor = "#E25159"
        var bookingBorderColor = '#E25159'
        var tooltip = ""
        if (parseInt(slot.interviewer_id) !== parseInt(currentInterviewerId)) {
          bookingColor = "#1F4672"
          tooltip = "「" + slot.web_id + "」の仕事に設定した面接枠です"
          var bookingBorderColor = '#1F4672'
        }
        if (slot.isCurrentJobSlot) {
          tooltip = "この仕事に設定した面接枠です"
        }
        else if (parseInt(slot.interviewer_id) === parseInt(currentInterviewerId)) {
          tooltip = "「" + slot.web_id + "」の仕事に設定した面接枠です"
          deleteColor = "#E25159";
        }
        var singleEvent = {}
        var startYear = moment
        singleEvent.start = new Date(slot.start_timestamp*1000)
        singleEvent.end = new Date(slot.end_timestamp*1000)
        if (moment(slot.end_timestamp*1000).format('HH:mm') == "00:00") {
          var timestamp = slot.end_timestamp - 100
          singleEvent.end = new Date(timestamp*1000)
        }

        singleEvent.id = slot.id
        singleEvent.showDelete = showDelete
        singleEvent.history = this.props.history
        singleEvent.tooltipAccessor = tooltip
        singleEvent.bookingColor = bookingColor
        singleEvent.deleteColor = deleteColor
        singleEvent.bookingBorderColor = bookingBorderColor
        singleEvent.interviewerId = slot.interviewer_id
        singleEvent.isCurrentJobSlot = slot.isCurrentJobSlot
        singleEvent.applicantId = applicantId
        singleEvent.handleDelete = this.deleteSlot.bind(this, slot.id)
        singleEvent.title = slot.interviewer_lastname
        return singleEvent
      })
    }

    var canAddSlots = new Date();
    // canAddSlots.setHours(canAddSlots.getHours() + 96);

    var dateText = moment().format("dddd")
    var dayText = moment().format("D")
    var monthText = moment().format("M")

    dateText = reactStringReplace(dateText, /(Monday)/g, (match, i) => (
		  <span key={i}>月曜日</span>
		));

    dateText = reactStringReplace(dateText, /(Tuesday)/g, (match, i) => (
		  <span key={i}>火曜日</span>
		));

    dateText = reactStringReplace(dateText, /(Wednesday)/g, (match, i) => (
		  <span key={i}>水曜日</span>
		));

    dateText = reactStringReplace(dateText, /(Thursday)/g, (match, i) => (
		  <span key={i}>木曜日</span>
		));

    dateText = reactStringReplace(dateText, /(Friday)/g, (match, i) => (
		  <span key={i}>金曜日</span>
		));

    dateText = reactStringReplace(dateText, /(Saturday)/g, (match, i) => (
		  <span key={i}>土曜日</span>
		));

    dateText = reactStringReplace(dateText, /(Sunday)/g, (match, i) => (
		  <span key={i}>日曜日</span>
		));

    var culture = "ja"

    let formats = {
      timeGutterFormat: 'HH:mm',
      eventTimeRangeFormat: ({
          start,
          end
        }, culture, local) =>
        local.format(start, 'HH:mm', 'ja') + '-' +
        local.format(end, 'HH:mm', 'ja'),
      dayFormat: (date, culture, localizer) =>
        localizer.format(date, 'D' + ' (dd)', 'ja'),
      agendaTimeRangeFormat: ({
          start,
          end
        }, culture, local) =>
        local.format(start, 'HH:mm', 'ja') + '-' +
        local.format(end, 'HH:mm', 'ja'),
      agendaDateFormat: (date, culture, localizer) =>
        localizer.format(date, 'D' + ' (dd)', 'ja'),

    }

    // const defaultDate = new Date();
    // defaultDate.setHours( defaultDate.getHours() + 96 );

    
    let open_slots_count = 0;
    let expiry_timestamp = null;
    const j = this.state.jobs.find(x => x.id == singleJob.id)
    if(j) {
      open_slots_count = j.open_slots_count;
      expiry_timestamp = j.expiry_timestamp;
    }
    console.log({myEvents})
    return (
      <div className="add-interview-times-container">
        <InnerHeader
          primary={false}
          history={this.props.history}
          headerText={DashboardTranslations.add_interview_times_page_header}
          backArrowOnClick={this.backArrowOnClick.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />
        {!loading && <div className="add-interview-times-inner">
          <div className='main-info-container'>
            <div className='slots-container'>
              <div className='slots-value'>{open_slots_count}</div>
              <div className='slots-subvalue'>面接枠残</div>
            </div>
            <div className='main-info-text'>
              <span className='blue'>{currentJobName}</span>の面接枠を<br/>
              面接担当者<span className='blue'>{interviewerLastName}さん</span>の名前で追加します。
              <img src={questionMark} height='32' width='32' alt="ss" onClick={() => this.closeInfoModalAddSlotCalendar()} style={{cursor: 'pointer'}}/>
            </div>
          </div>
          <LoadingOverlay
            active={specialLoading}
            spinner
            >
            <div className="add-section">
              <BigCalendar
                localizer={localizer}
                events={myEvents.sort((a, b) =>
                  (a.isCurrentJobSlot ? 2 : a.showDelete === false ? 1 : 0) -
                  (b.isCurrentJobSlot ? 2 : b.showDelete === false ? 1 : 0)
                )}
                startAccessor="start"
                endAccessor="end"
                timeslots={1}
                formats={formats}
                culture={'ja'}
                defaultView="week"
                views={['week']}
                scrollToTime={new Date(1970, 1, 1, 9)}
                // defaultDate={defaultDate}
                onSelectSlot={(details) => this.onSelectSlot(details)}
                selectable={true}
                step={30}
                eventPropGetter={event => ({
                  style: {
                    backgroundColor: event.isCurrentJobSlot === true ? "#E25159" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "#FFFFFF" : "#1F4672",
                    border: event.isCurrentJobSlot === true ? "none" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "2px solid #E25159" : "none",
                    borderLeft: event.isCurrentJobSlot === true ? "2px solid #FFF" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "2px solid #E25159" : "2px solid #FFF",
                    borderRight: event.isCurrentJobSlot === true ? "2px solid #FFF" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "2px solid #E25159" : "2px solid #FFF",
                    color : event.isCurrentJobSlot === true ? "#FFFFFF" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "#E25159" : "#FFFFFF",
                  },
                })}
                components={{
                  toolbar : CustomToolbar,
                  event: CustomEvent,
                }}
                slotPropGetter={date => ({
                  className: isGapAtLeast24Hours(date, canAddSlots) ? "rbc-timeslot-group-hover" : ""
                })}
              />
            </div>
            <div className="mobile-add-section">
              <BigCalendar
                localizer={localizer}
                events={myEvents.sort((a, b) =>
                  (a.isCurrentJobSlot ? 2 : a.showDelete === false ? 1 : 0) -
                  (b.isCurrentJobSlot ? 2 : b.showDelete === false ? 1 : 0)
                )}
                startAccessor="start"
                endAccessor="end"
                timeslots={1}
                formats={formats}
                // defaultDate={defaultDate}
                defaultView="day"
                views={['day']}
                scrollToTime={new Date(1970, 1, 1, 9, 0, 0)}
                onSelectSlot={(details) => this.onSelectSlot(details)}
                selectable={true}
                step={30}
                eventPropGetter={event => ({
                  style: {
                    backgroundColor: event.isCurrentJobSlot === true ? "#E25159" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "#FFFFFF" : "#1F4672",
                    border: event.isCurrentJobSlot === true ? "none" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "2px solid #E25159" : "none",
                    borderLeft: event.isCurrentJobSlot === true ? "2px solid #FFF" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "2px solid #E25159" : "2px solid #FFF",
                    borderRight: event.isCurrentJobSlot === true ? "2px solid #FFF" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "2px solid #E25159" : "2px solid #FFF",
                    color : event.isCurrentJobSlot === true ? "#FFFFFF" : parseInt(event.interviewerId) === parseInt(currentInterviewerId) ? "#E25159" : "#FFFFFF",
                  },
                })}
                components={{
                  toolbar : CustomToolbar,
                  event: CustomEvent,
                }}
                slotPropGetter={date => ({
                  className: isGapAtLeast24Hours(date, canAddSlots) ? "rbc-timeslot-group" : ""
                })}
              />
            </div>
          </ LoadingOverlay>
        </div>}
        <SuccessModal successMessage={successMessage} handleClose={this.handleClose.bind(this)} showSuccess={showSuccess}/>
        <ErrorModal newModal errorTitle={errorTitle} error={error} handleClose={this.handleClose.bind(this)} showError={showError}/>
        {showAddSlotCalendarModal && <InfoModalAddSlotCalendar closeInfoModalAddSlotCalendar={this.closeInfoModalAddSlotCalendar.bind(this)} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        lastName: state.user.lastName,
        language: state.language,
        token: state.user.token,
        joyride: state.joyride,
        user: state.user
    }
};

AddInterviewTimes = connect(mapStateToProps)(AddInterviewTimes);


export default AddInterviewTimes;
